import React from 'react'

import Item from '../components/Item.jsx'

import Sliders from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Testimonial() {

    const testimonials = [
        {   
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-1.jpg',
            'message': 'Great skills and commitment from Randy. Couldn\'t ask for more',
            'name': 'sidestepthenorm',
            'position': 'Via Upwork'
        }, 
        {
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-2.jpg',
            'message': 'Randy is completely a proffesional freelancer. The communication was fluently and he has knowledge about this work. I recommend your services. Thank you.',
            'name': 'Dmitry Balanda',
            'position': 'Via Upwork'
        },
        {
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-3.jpg',
            'message': 'Randy is a very talented developer who always thinks through every step and strives to deliver an optimal result. Communication with him was always effective and goal-oriented. We will certainly continue our cooperation in the near future.',
            'name': 'Transport Talent',
            'position': 'Via Upwork'
        },
        {
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-4.jpg',
            'message': 'Randy completed the job efficiently and without any supervision, he got the job and ran with it with no issues at all. Excellent work, thank you Randy. I highly recommend Randy for his expertise.',
            'name': 'Private',
            'position': 'Via Upwork'
        },
        {
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-4.jpg',
            'message': 'Randy was a pleasure to work with. He is extremely knowledgeable when it came to working with WordPress and was able to finish the job that was set for him. His communication was very clear and he finished the job before I expect him to. I will definitely work with him again in the future.',
            'name': 'Private',
            'position': 'Via Upwork'
        },
        {
            'image': 'https://nairo.ibthemespro.com/img/testimonial/team-4.jpg',
            'message': 'We have worked with Randy before and keep hiring him because we know with him we get a reliable and skilled developer. Communication is always easy and he replies to comments very quickly. Working with him is truly a joy and we could not be happier with the result.',
            'name': 'Transport Talent',
            'position': 'Via Upwork'
        }
    ];

    const settings = {
        centerMode: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScrol: 2,
        speed: 500,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const items = testimonials.map((item, index) =>
        <Item key={index} name={item.name} message={item.message} position={item.position} image={item.image} />
    );

    return (
        <div className="container">
            <Sliders {...settings} className="">
                {items}
            </Sliders>
        </div>
    )
}

export default Testimonial
