import React from 'react'

import randyImage from '../assets/images/randy.png'
import { HashLink } from 'react-router-hash-link'

function Hero() {
    return (
        <div className="container">
            <div className="row ">
                <div className="min-h-screen items-center flex pb-40  z-0">
                    <div className="text-opacity-10 hero-content flex flex-wrap flex-col md:flex-row">
                        <div className="md:order-1 order-2 hero-left flex items-center">
                            <div className="md:pt-24 md:pb-14 pb-0 pt-12 top-5 relative">
                                <h1 className="md:text-7xl font-black text-5xl my-8">I'm <strong className="text-accent">Randy Corpuz</strong></h1>
                                <div className="uppercase font-semibold text-3xl md:text-4xl text-accent opacity-80">Freelance Web Developer</div>
                                <p className="text-lg max-w-xl font-light my-8 mb-16 font-montserrat">I develop services for customers of all sizes, specializing in creating stylish, modern websites and web app.</p>
                                <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="button text-xl" to="#contact">Let's Talk</HashLink>
                            </div>
                        </div>

                        <div className="md:order-2 order-1 hero-right relative mt-20 md:mt-30">
                            <div className="thumbnail">
                                <div className="inner flex justify-center">
                                    <img className="w-full" src={randyImage} alt="Personal Portfolio Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
