import React, { useState } from "react";

import { HashLink } from 'react-router-hash-link'
import { Menu, Transition } from '@headlessui/react'

import Tooltip from '../components/Tooltip.jsx'

function Sidebar () {

    const [menuStatus, setMenuStatus] = useState(true);
    const [openMenu, setOpenMenu] = useState(false);
    const [active, setActive] = useState('home');

    const navigation = menuStatus ? (
        
        <Transition
            className="z-40"
                show={menuStatus}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
        >

            <div className={"sidebar bg-white w-20 fixed h-screen border border-border md:block left-0 top-0 z-40 " + (openMenu ? 'block menu-open' : 'hidden') } >
                <div className="logo flec">
                    <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} className="flex items-center justify-center bg-accent text-white font-semibold text-4xl w-20 h-20 align-top text-center" to="#home">
                        RC
                    </HashLink>
                </div>
                <ul className="navigation flex flex-wrap pl-0 mb-0 list-none">
                
                    <li className="w-full flex justify-center p-4 border-b border-border cursor-pointer">
                        <Tooltip tooltipText="Home" position="left">
                            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} onClick={() => setActive('home')} className={"" + (active == 'home' ? 'text-accent' : 'text-black')} to="#home" >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <span className="text-accent hidden">Home</span>
                            </HashLink>
                        </Tooltip>
                    </li>
                    <li className="w-full flex justify-center p-4 border-b border-border cursor-pointer">
                        <Tooltip  tooltipText="About" position="left">
                            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} onClick={() => setActive('about')} className={"" + (active == 'about' ? 'text-accent' : 'text-black')} to="#about">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className=" hidden">About</span>
                            </HashLink>
                        </Tooltip>
                    </li>
                    <li className="w-full flex justify-center p-4 border-b border-border cursor-pointer">
                        <Tooltip tooltipText="Work" position="left">
                            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} onClick={() => setActive('work')} className={"" + (active == 'work' ? 'text-accent' : 'text-black')} to="#work">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <span className=" hidden">Portfolio</span>
                            </HashLink>
                        </Tooltip>
                    </li>
                    <li className="w-full flex justify-center p-4 border-b border-border cursor-pointer">
                        <Tooltip tooltipText="Contact" position="left">
                            <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} onClick={() => setActive('contact')} className={"" + (active == 'contact' ? 'text-accent' : 'text-black')} to="#contact">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                <span className=" hidden">Portfolio</span>
                            </HashLink>
                        </Tooltip>
                    </li>  
                    {/* <li className="w-full flex justify-center p-4 border-b border-border cursor-pointer">
                        <Tooltip tooltipText="Hire me on Upwork" position="left">
                            <a className="text-accent fill-current" target="_blank" rel="nofollow sponsored" href="https://www.upwork.com/freelancers/~011d903f73b1c2d33f">
                                <svg className="h-8 w-8" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Upwork</title><path d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076.008-.042c.207-1.143.849-3.06 2.839-3.06 1.492 0 2.703 1.212 2.703 2.703-.001 1.489-1.212 2.702-2.704 2.702zm0-8.14c-2.539 0-4.51 1.649-5.31 4.366-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112c-.002 1.406-1.141 2.546-2.547 2.548-1.405-.002-2.543-1.143-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303 2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109 3 0 5.439-2.452 5.439-5.45 0-3-2.439-5.439-5.439-5.439z"/></svg>
                                <span className=" hidden">Upwork</span>
                            </a>
                        </Tooltip>
                    </li>    */}
                </ul>
            </div>

        </Transition>

    ) : '';

    function handleClick(status) {
        setOpenMenu(status)
        // setMenuStatus(!menuStatus)
    }

    return (
        <div className="aside z-40">
            { navigation }

            <div className="fixed bottom-8 right-8 z-50 flex md:hidden">
                <div className="rounded-full bg-accent cursor-pointer" onClick={() => handleClick(!openMenu)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;