import React from 'react'

function Tooltip({ children, tooltipText, position }) {
    const tipRef = React.createRef(null);

    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
        tipRef.current.style.marginLeft = "20px";
    }

    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
        tipRef.current.style.marginLeft = "10px";
    }

    return (
        <div
            className="relative flex items-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className="z-20 w-max absolute whitespace-no-wrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150"
                style={position == 'left' ? { left: "100%", opacity: 0 } : { bottom: "100%", opacity: 0 }}
                ref={tipRef}
            >
                {tooltipText}
            </div>
            {children}
        </div>
    );
}

export default Tooltip