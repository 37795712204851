import React, {Component} from 'react';

import upwork from "../assets/images/logo/logo-upwork.svg"

function Item({ name, image, message, position }) {
    return (
        <div className="testimonial-container bg-white md:p-9 p-6 overflow-hidden mt-4 mb-6 w-full shadow-img flex items-start">
            <div className="avatar min-w-max relative">
                <svg className="svg-icon absolute w-24 h-24 text-accent md:opacity-100 opacity-30 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M128 472.896h341.344v341.344H128zM128 472.896L272.096 192h110.08l-144.128 280.896z"  /><path d="M544 472.896h341.344v341.344H544zM544 472.896L688.096 192h110.08l-144.128 280.896z"  /></svg>
            </div>
            <div className="body md:pl-28 pl-0 flex-1 flex flex-col self-center z-10">
                <p className="mb-6">{message}</p>
                <h6>{name}</h6>
                <span>{position}</span>
            </div>
        </div>
    );
}

export default Item;