import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom"

import "./assets/css/main.css";

import Header from "./components/Header.jsx"
import About from "./components/About.jsx"
import Website from "./components/Website.jsx"
import Contact from "./components/Contact.jsx"
import Footer from "./components/Footer.jsx"

ReactDOM.render(
    <BrowserRouter>

        <Header />

        <About />

        <Website />

        <Contact />

        <Footer />
      
    </BrowserRouter>,
    document.getElementById("root")
);
