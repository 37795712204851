import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";

import Sidebar from "../components/Sidebar.jsx"
import Hero from "../components/Hero.jsx"

function Header() {
    return (
        <section className="w-full" id="home">
            <Sidebar />
            <Hero />
        </section>
    );
}

export default Header;
