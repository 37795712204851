import React from 'react'

function Footer() {
    return (
        <div className="footer bg-gray-800 relative w-full flex items-center z-40">
            <div className="container">
                <div className="row flex justify-center">
                    <div className="py-8 text-white flex items-center">
                        © 2021 RandyPH.com. All Rights Reserved
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
